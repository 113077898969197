<template>
    <div class="fixed top-0 left-0 w-full h-screen">
        <iframe
            src="https://www.vturas.lt/360turas/Kotas"
            name="Kotas"
            width="100%"
            height="100%"
            frameborder="0"
            allow="fullscreen; accelerometer;
    gyroscope; magnetometer; vr"
            allowfullscreen="true"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            oallowfullscreen="true"
            msallowfullscreen="true"
        ></iframe>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
