<template>
  <div class="flex container mx-auto w-3/4 relative">
    <div class="md:w-3/4 lg:w-1/2 mt-24 relative">
      <div class="text-textMob mt-40 md:mt-0 md:text-xl font-bold">
        Žūklės reikmenų video parduotuvė
      </div>
      <div class="text-textMobSub md:text-sm text-gray mt-4 md:mt-9 mb-8">
        Pirmoji Lietuvoje žūklės reikmenų video parduotuvė. Video skambučio
        pagalba padėsime jums išsirinkti tinkamiausius įrankius, realiuoju laiku
        suformuosime pirkinių krepšelį, o prekes pristatysime akimirksniu.
      </div>
      <div class="action-button lg:flex">
        <mainBtn
          @on-click="openMessenger"
          text="Video apsipirkimas"
          icon="camera"
          textColor="white"
          bgColor="green"
        />
        <mainBtn
          @on-click="$root.scrollToHowItWorks"
          class="mt-3 lg:mt-0 sm:ml-4 lg:ml-4"
          text="Kaip tai veikia?"
          icon="question"
          textColor="green"
          bgColor="yellow"
        />
      </div>
      <div class="mt-8 lg:mt-4 flex justify-start">
        <iconText text="Patogu" icon="clock" />
        <iconText text="Saugu" icon="check" />
        <iconText text="Greita" icon="zap" />
      </div>
    </div>
    <div style="z-index:-1" class="z-0 md:w-1/2 md:relative">
      <div>
        <svg
          class="hidden md:block"
          width="827"
          height="809"
          viewBox="0 0 827 809"
          fill="none"
        >
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="-23"
            width="832"
            height="832"
          >
            <rect
              y="392.75"
              width="587.959"
              height="587.959"
              rx="100"
              transform="rotate(-45 0 392.75)"
              fill="#014C40"
            />
          </mask>
          <g mask="url(#mask0)">
            <rect
              x="-350.217"
              y="-28.3063"
              width="1218.33"
              height="813.989"
              fill="url(#pattern0)"
            />
          </g>
          <defs>
            <pattern
              id="pattern0"
              patternContentUnits="objectBoundingBox"
              width="1"
              height="1"
            >
              <use
                xlink:href="#image0"
                transform="translate(-0.00011206) scale(0.000244195 0.000365497)"
              />
            </pattern>
            <image
              id="image0"
              width="4896"
              height="2736"
              xlink:href="/assets/shop.jpg"
              alt=""
            />
          </defs>
        </svg>
        <svg
          class="-top-22 md:hidden -mt-8 absolute -right-10"
          width="307"
          height="335"
          viewBox="0 0 307 335"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <mask
            id="mask1"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="335"
            height="335"
          >
            <rect
              y="167.5"
              width="236.881"
              height="236.881"
              rx="60"
              transform="rotate(-45 0 167.5)"
              fill="#014C40"
            />
          </mask>
          <g mask="url(#mask1)">
            <rect
              x="-141.098"
              y="-2.13785"
              width="490.849"
              height="327.945"
              fill="url(#pattern1)"
            />
          </g>
          <defs>
            <pattern
              id="pattern1"
              patternContentUnits="objectBoundingBox"
              width="1"
              height="1"
            >
              <use
                xlink:href="#image1"
                transform="translate(-0.000112009) scale(0.000244195 0.000365497)"
              />
            </pattern>
            <image
              id="image1"
              width="4896"
              height="2736"
              xlink:href="/assets/shop.jpg"
              alt=""
            />
          </defs>
        </svg>
      </div>
    </div>
    <img
      @click="toggleShowTour"
      class="absolute z-50 cursor-pointer w-32 md:w-64 top-16 md:top-1/2 pl-4 right-3/4 md:-right-20"
      src="/assets/3dturas.svg"
      alt="3d turas"
      style="z-index:10000"
    />
    <turas v-if="showTour" />
  </div>
</template>

<script>
// import Vue from "vue";
// import checkView from "vue-check-view";
import turas from "../turas";
import { mapState } from "vuex";
import mainBtn from "../smallComponents/mainBtn";
import iconText from "../smallComponents/iconText";
// Vue.use(checkView);

export default {
  components: {
    mainBtn,
    turas,
    iconText
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      showTour: state => state.showTour
    })
  },
  methods: {
    toggleShowTour() {
      this.$store.commit("toggleShowTour");
    },
    openMessenger() {
      this.$store.commit("openMessenger");
      this.$store.commit("setShowMessenger", true);
    },
    viewHandler(ev) {
      console.log(ev);
    }
  }
};
</script>

<style></style>
