<template>
  <div>
    <homepageIntroBlock @open-messenger="openMessenger = !openMessenger" />
    <aboutUs />
  </div>
</template>

<script>
import homepageIntroBlock from "./components/homepage/homepageIntroBlock";
import aboutUs from "./components/homepage/aboutUs";

export default {
  name: "index",
  data() {
    return {};
  },
  components: {
    homepageIntroBlock,
    aboutUs,
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss"></style>
