<template>
  <div id="apie-mus" class="container mx-auto lg:flex -mt-2">
    <div class="w-full lg:w-1/3">
      <svg
        class="w-3/4 lg:w-96 mx-auto"
        width="422"
        height="438"
        viewBox="0 0 422 438"
        fill="none"
      >
        <mask
          id="mask3"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="20"
          y="16"
          width="382"
          height="382"
        >
          <rect
            x="4"
            y="203.749"
            width="288.144"
            height="297.34"
            rx="40"
            transform="rotate(-45 4 203.749)"
            fill="#014C40"
          />
        </mask>
        <g mask="url(#mask3)">
          <rect
            x="-248.853"
            width="689.404"
            height="460.323"
            fill="url(#pattern3)"
          />
        </g>
        <defs>
          <pattern
            id="pattern3"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use
              xlink:href="#image3"
              transform="translate(-0.00078373) scale(0.000607011 0.000909091)"
            />
          </pattern>
          <image
            id="image3"
            width="2250"
            height="1100"
            xlink:href="/assets/fisherman.jpg"
          />
        </defs>
      </svg>
    </div>
    <div class="lg:ml-24 w-4/5 mx-auto lg:w-full -mt-20 lg:mt-8">
      <div class="text-textMob lg:text-xl font-bold">Apie mus</div>
      <div class="text-textMobSub lg:text-sm mt-8 lg:max-w-xl text-gray">
        Esame užkietėję žvejai, jau 12 metų dalinamės savo patirtimi ir siekiame
        tiekti moderniausius žūklės įrankius meškerės broliams. Visada siekėme
        pažangos, o šiais, pokyčių laikais, pristatome jums dar vieną naujieną –
        pirmą Lietuvoje, o gal ir Pasaulyje, žūklės reikmenų parduotuvę,
        pagrįstą video skambučiu. Tik pas mus galėsite neišeidami iš namų gyvai
        apžiūrėti mūsų pardutuvėje esančias prekes, pasitarti su pardavėju ir
        apsipirkti realiuoju laiku. <br />
        Jūsų pirkinių krepšelį suformuosime pokalbio metu, o prekes, eant
        reikalui, pristatysime per 2 valandas*. Viskas labai paprasta – jei
        reikia, apsižvalgykite parduotuvėje naudodami video turą. Jau žinote ko
        norite? O gal turite keletą klausimų? Galbūt norite apžiūrėti išsamiau
        ir pamatyti, kaip prekė atrodo demonstruojama gyvai? Vienas mygtukas ir
        mes kartu su jumis. Pirmyn! Ženkime pokyčių keliu kartu!
        <br />
        <br />
        <span class="text-gray-500 text-extrasm"
          >*pristatymas per 2 valandas galimas tik Vilniuje.</span
        >
      </div>
      <mainBtn
        class="mt-8 hidden"
        text="Plačiau"
        icon="arrow"
        iconPos="right"
        textColor="green"
        bgColor="yellow"
      />
    </div>
  </div>
</template>

<script>
import mainBtn from "../smallComponents/mainBtn";

export default {
  components: { mainBtn },
};
</script>

<style></style>
